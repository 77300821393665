import Vue from 'vue';
import VueRouter from 'vue-router';
import { Notify } from 'vant';


import Home from '../views/Home.vue';
import Index from '../views/index/index.vue'; //首页

import trade from '../views/trade/index.vue'; //团队
import lease from '../views/trade/lease.vue'; //算力租赁
import leaseRecord from '../views/trade/leaseRecord/index.vue';  //租赁记录
import statistics from '../views/trade/statistics.vue'; //社区长团队统计
import orders from '../views/trade/orders.vue';  //订单页面

import bulletin from '../views/bulletin/index.vue'; //公告
import bulletinDetail from '../views/bulletin/details.vue'; //公告详情

import mine from '../views/mine/index.vue';  //我的
import invite from '../views/mine/invite.vue';  //邀请
import usdtWithdraw from '../views/mine/usdtWithdraw.vue';  //提现
import transfer from '../views/mine/transfer.vue';  //提现
import withdrawRecord from '../views/mine/withdrawRecord.vue';  //提现记录
import seso from '../views/mine/seso.vue';  //seso
import assets from '../views/mine/assets.vue';  //我的资产
import introduce from '../views/introduce/index.vue';  //平台介绍
import datainfo from '../views/datainfo/index.vue';  //首页数据页面
import assetDetails from '../views/mine/assetDetails.vue';  //资产明细页面
import team from '../views/mine/team.vue';  //我的团队页面
import personal from '../views/mine/personal.vue';  //个人中心页面
import partition from '../views/mine/partition.vue';  //申请分区

import service from '../views/service/index.vue';  //服务器页面
import buyRecord from '../views/service/buyRecord.vue';  //购买服务器记录页面
// import etcWithdraw from '../views/mine/etcWithdraw.vue';  //etc提现页面
// import etcExchange from '../views/mine/etcExchange.vue';  //etc兑换页面
import etcManage from '../views/mine/etcManage.vue';  //etc管理页面
import smhManage from '../views/mine/smhManage.vue';  //smh管理页面
import aleoWithdraw from '../views/mine/aleoWithdraw.vue';  //aleo提现页面
Vue.use(VueRouter);
const routes = [

  {
    path: '/',
    redirect: '/',
    component: Home,
    children: [
      {
        path: '/',
        name: 'Index',
        component: Index,
      },
      {
        path: 'trade',
        name: 'trade',
        component: trade,
        meta: {
          requiresAuth: true
        }
      },
      // {
      //   path: 'lease',
      //   name: 'lease',
      //   component: lease,
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      {
        path: 'mine',
        name: 'mine',
        component: mine,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'invite',
        name: 'invite',
        component: invite,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'usdtWithdraw',
        name: 'usdtWithdraw',
        component: usdtWithdraw,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'transfer',
        name: 'transfer',
        component: transfer,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'withdrawRecord',
        name: 'withdrawRecord',
        component: withdrawRecord,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'assets',
        name: 'assets',
        component: assets,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'bulletin',
        name: 'bulletin',
        component: bulletin,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'bulletinDetail',
        name: 'bulletinDetail',
        component: bulletinDetail,
        meta: {
          requiresAuth: true
        }
      },
      // {
      //   path: 'introduce',
      //   name: 'introduce',
      //   component: introduce,
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: 'datainfo',
      //   name: 'datainfo',
      //   component: datainfo,
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: 'leaseRecord',
      //   name: 'leaseRecord',
      //   component: leaseRecord,
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: 'service',
      //   name: 'service',
      //   component: service,
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: 'buyRecord',
      //   name: 'buyRecord',
      //   component: buyRecord,
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: 'statistics',
      //   name: 'statistics',
      //   component: statistics,
      //   meta: {
      //     requiresAuth: true
      //   }
      // },etcExchange
      // {
      //   path: 'etcExchange',
      //   name: 'etcExchange',
      //   component: etcExchange,
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      // {
      //   path: 'etcWithdraw',
      //   name: 'etcWithdraw',
      //   component: etcWithdraw,
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      {
        path: 'assetDetails',
        name: 'assetDetails',
        component: assetDetails,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'team',
        name: 'team',
        component: team,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'orders',
        name: 'orders',
        component: orders,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'personal',
        name: 'personal',
        component: personal,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'seso',
        name: 'seso',
        component: seso,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'partition',
        name: 'partition',
        component: partition,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'etcManage',
        name: 'etcManage',
        component: etcManage,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'smhManage',
        name: 'smhManage',
        component: smhManage,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'aleoWithdraw',
        name: 'aleoWithdraw',
        component: aleoWithdraw,
        meta: {
          requiresAuth: true
        }
      },
    ],
  },
];



const router = new VueRouter({
  // mode: 'hash',
  mode: 'history',
  // base: './',
  routes,
});

// router.beforeEach((to, from, next) => {
//   let parentId = localStorage.getItem('parentId')
//   if (to.meta.requiresAuth) {
//     if (parentId != 0) {
//       // 判断是否有上级
//       next()
//     } else {
//       next({ path: "/" })
//     }
//   } else {
//     next()
//   };
// });

export default router;
