import request from '../utils/request';

export default {
  //公告列表
  getAnnouncement(data) {
    return request.request({
      url: '/announcement/list',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //公告详情
  getAnnouncementDetail(data) {
    return request.request({
      url: '/announcement/show',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //租赁算力
  rental(data) {
    return request.request({
      url: '/home/rental',
      method: "post",
      headers: {
        // Authorization: address,        
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //首页的各项数据 
  getIndexAllInfo(data) {
    return request.request({
      url: '/home/base',
      method: "post",
      headers: {
        // Authorization: address,        
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //用户信息
  getUserInfo(data) {
    return request.request({
      url: '/user/userInfo',
      method: "post",
      headers: {
        // Authorization: address
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //注册
  register(data) {
    return request.request({
      url: '/member/register',
      method: "post",
      headers: {
        // Authorization: address,
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //用户信息/登录
  auth(data) {
    return request.request({
      url: '/member/auth',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //提现记录
  withdrawList(data) {
    return request.request({
      url: '/offline/withdrawList',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //算力明细列表
  powerList(data) {
    return request.request({
      url: '/ledger/powerList',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //USDT明细列表
  usdtList(data) {
    return request.request({
      url: '/ledger/usdtList',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //虚拟币明细列表
  tokenList(data) {
    return request.request({
      url: '/ledger/tokenList',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //团队数据
  teamInfo(data) {
    return request.request({
      url: '/team/all',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //提现申请
  withdrawCash(data) {
    return request.request({
      url: '/offline/withdrawCash',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //提现信息
  withdrawInfo(data) {
    return request.request({
      url: '/offline/withdrawFee',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //租赁比例
  prepareRental(data) {
    return request.request({
      url: '/home/prepareRental',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //账变类型
  actionGet(data) {
    return request.request({
      url: '/ledger/actionGet',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //团队收益列表
  profitList(data) {
    return request.request({
      url: '/team/profitList',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //充值记录
  rechargeList(data) {
    return request.request({
      url: '/offline/rechargeList',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //提现手续费
  withdrawFee(data) {
    return request.request({
      url: '/offline/withdrawFee',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //服务器信息
  serversInfo(data) {
    return request.request({
      url: '/servers/info',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //服务器购买
  serversRental(data) {
    return request.request({
      url: '/servers/rental',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //服务器购买列表
  serversList(data) {
    return request.request({
      url: '/order/list',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //团队统计
  serviceTeam(data) {
    return request.request({
      url: '/servers/team',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //ETC金额数据
  etcInfo(data) {
    return request.request({
      url: '/user/etcInfo',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //ETC提现
  etcSubmit(data) {
    return request.request({
      url: '/user/etcSubmit',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //预约
  reservation(data) {
    return request.request({
      url: '/Transaction/reservation',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //ETC兑换提交
  etcExchange(data) {
    return request.request({
      url: '/user/etcExchange',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //捡漏
  leak(data) {
    return request.request({
      url: '/Transaction/leak',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //传统交易
  tradition(data) {
    return request.request({
      url: '/Transaction/tradition',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //我的资产
  assets(data) {
    return request.request({
      url: '/ledger/assets',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //资产明细列表
  coinList(data) {
    return request.request({
      url: '/ledger/coinList',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //订单列表
  orderList(data) {
    return request.request({
      url: '/order/list',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //预约下单
  createReservation(data) {
    return request.request({
      url: '/order/createReservation',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //捡漏下单
  createLeak(data) {
    return request.request({
      url: '/order/createLeak',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //传统下单
  createTradition(data) {
    return request.request({
      url: '/order/createTradition',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //能量值互转
  transferEnergy(data) {
    return request.request({
      url: '/ledger/transferEnergy',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //资产资金变动类型
  coinAction(data) {
    return request.request({
      url: '/ledger/coinAction',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //订单筛选类型
  orderAction(data) {
    return request.request({
      url: '/order/orderAction',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //更新订单hash
  updateOrder(data) {
    return request.request({
      url: '/order/updateOrder',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //获取订单
  getOrder(data) {
    return request.request({
      url: '/order/getOrder',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //申请分区
  partitionApply(data) {
    return request.request({
      url: '/user/partitionApply',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //操作订单，卖出或租赁
  handleOrder(data) {
    return request.request({
      url: '/order/handleOrder',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //产品列表
  productsList(data) {
    return request.request({
      url: '/order/productsList',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //创建订单
  createOrder(data) {
    return request.request({
      url: '/order/create',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //SMH兑换提交
  smhExchange(data) {
    return request.request({
      url: '/user/smhExchange',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //SMH金额数据
  smhInfo(data) {
    return request.request({
      url: '/user/smhInfo',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //SMH提现
  smhSubmit(data) {
    return request.request({
      url: '/user/smhSubmit',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //ALEO金额数据
  aleoInfo(data) {
    return request.request({
      url: '/user/aleoInfo',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
  //ALEO提现
  aleoSubmit(data) {
    return request.request({
      url: '/user/aleoSubmit',
      method: "post",
      headers: {
        'Lang': localStorage.getItem('lang') || '',
      },
      data
    })
  },
}